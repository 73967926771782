import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './style'
import { SuperTag, Link } from '~components';
import VideoJSFive from '../Videojs/VideoJSFive';

export default function TalentWidget(){
  
return(
      
<Widget>
  <Container>
  <Widget.Block>
    <Row className=" justify-content-lg-start">
    <Col xs="12" className="col-xl-5 col-lg-5 col-md-8 col-xs-10">
    <Widget.Image>
    <VideoJSFive videoId="p8tdeuqai7" wrapper="wistia-player-container-2"/>
    </Widget.Image>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <Widget.Box>
        <Link to="/customer-stories"><Widget.MiniHeading><Widget.BackButton textColor="#000"><i class='fas fa-angle-left'></i>  Customer Stories</Widget.BackButton> </Widget.MiniHeading></Link>
          <Widget.Heading as="h2"> <SuperTag value="How Talent Collective Boosted Its ROI With We-Connect’s Event Promotion"/> </Widget.Heading>
        </Widget.Box>
      </Col>
    </Row>
    </Widget.Block>

    <Widget.Block>
    <Row className="justify-content-lg-start">
    <Col xs="12" className="col-xl-4 col-lg-4 col-md-8 col-xs-10">
        <Widget.Card>
        <Widget.Company as="h2">Talent Collective</Widget.Company>
        <Widget.Text>Talent Collective is a non-profit membership community empowering women in talent acquisition through personalized professional development and leadership training.</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Industry</b> </Widget.SubPara>
        <Widget.Text>Recruiting</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Company Size</b> </Widget.SubPara>
        <Widget.Text> 2-10 employees</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Features Used</b> </Widget.SubPara>
        <Widget.Text>Event promotion, smart sequences</Widget.Text>
        </Widget.Card>
      </Col>
    <Col className="col-xl-7 col-lg-7 col-md-8 col-xs-10">

        <Widget.Title as="h2"><b>Customize Your Approach to Outreach Automation</b></Widget.Title>
        <Widget.Text>Natalie Stones is the Founder of two companies: Talent Collective & Talent Refinery. The former is dedicated to educating other female recruiters. The latter focuses on Natalie’s own skills, where she acts as a fractional head of talent for startups and small businesses. 
        </Widget.Text>
        <Widget.Text>
        After trying other automation tools, Natalie landed on We-Connect. She uses the tool at both businesses for a wide range of tasks. At Talent Refinery, she leverages We-Connect for her business development efforts and to find qualified talent for her clients. At her non-profit, she runs event promotion campaigns with automated follow-up messages.
        </Widget.Text>
        <Widget.Text>
        We talked to Natalie about how We-Connect has improved her business. Hear what she had to say in the video.
        </Widget.Text>

        <Widget.CardTwo>
        <Widget.SubPara> Start your free trial and start kicking butt like this company did.</Widget.SubPara>
        <Widget.Button target="_blank" to="//account.we-connect.io/signup" className="btn btn-primary text-white">14 day free trial</Widget.Button>
        </Widget.CardTwo>
      </Col>
    </Row>
    </Widget.Block>
    
  </Container>
</Widget>

)
}